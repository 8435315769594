var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm.$options.components['profile-need-help'] ? [_c('profile-need-help')] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "mt-4"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('h4', {
    staticClass: "heading"
  }, [_vm._v(" Ticket ID: " + _vm._s(_vm.supportTicketDetail ? _vm.supportTicketDetail.ticketNo : "") + " ")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.supportTicketDetail ? _vm.supportTicketDetail.description : "") + " ")])]), _c('div', [_c('div', {
    staticClass: "btn"
  }, [_vm.supportTicketDetail.status === 'OPEN' ? _c('b-button', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(_vm._s(_vm.supportTicketDetail ? _vm.supportTicketDetail.status : ""))]) : _vm.supportTicketDetail.status === 'CLOSED' ? _c('b-button', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.supportTicketDetail ? _vm.supportTicketDetail.status : ""))]) : _vm._e()], 1), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.dateFormat))])])]), _c('span', [_c('h6', {
    staticClass: "mt-3 mb-3"
  }, [_vm._v("Support ticket activity history")]), _c('b-table', {
    attrs: {
      "responsive": "",
      "head-variant": "light",
      "outlined": "",
      "fields": _vm.fields,
      "items": _vm.supportTicketDetail.worknoteHistory
    },
    scopedSlots: _vm._u([{
      key: "cell(attachments)",
      fn: function fn(data) {
        return [data.value.url ? _c('a', {
          on: {
            "click": function click($event) {
              return _vm.viewAttachment(data.item);
            }
          }
        }, [_c('b-icon', {
          staticClass: "ml-2",
          attrs: {
            "icon": "paperclip",
            "scale": "1.5",
            "rotate": "45"
          }
        })], 1) : _vm._e()];
      }
    }])
  }, _vm._l(_vm.items, function (item) {
    return _c('b-tr', {
      key: item._id
    });
  }), 1)], 1), _c('b-input-group', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.supportTicketDetail.status === 'OPEN',
      expression: "supportTicketDetail.status === 'OPEN'"
    }],
    staticClass: "mt-3"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Enter Comment"
    },
    model: {
      value: _vm.postComment,
      callback: function callback($$v) {
        _vm.postComment = $$v;
      },
      expression: "postComment"
    }
  }), _c('b-input-group-append', [_c('div', {
    staticClass: "form-group font-weight-bolder upload_file-Custome"
  }, [_c('button', {
    staticClass: "file-upload-sec paper-plane",
    attrs: {
      "variant": "primary"
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "paperclip",
      "rotate": "45"
    }
  })], 1), _c('b-form-file', {
    attrs: {
      "placeholder": "Upload file",
      "drop-placeholder": "Drop file here...",
      "state": Boolean(_vm.attachments)
    },
    on: {
      "change": _vm.readLogo
    }
  })], 1), _c('b-button', {
    staticClass: "paper-plane",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.onClickComment();
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "cursor-fill"
    }
  })], 1)], 1)], 1), _vm.attachments != '' ? _c('div', [_c('Span', [_c('strong', [_vm._v(" File Uploaded: ")]), _c('a', {
    staticClass: "onHover",
    on: {
      "click": function click($event) {
        return _vm.viewAttachmentFile(_vm.attachments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.attachments) + " ")])]), _c('b-button', {
    staticClass: "ml-3",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.onClickRemoveFile();
      }
    }
  }, [_vm._v(" Remove File ")])], 1) : _vm._e()], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }