<template>
  <b-container>
    <b-row>
      <b-col md="3">
        <template v-if="$options.components['profile-need-help']">
          <profile-need-help />
        </template>
      </b-col>
      <b-col md="9">
        <div class="mt-4">
          <div class="d-flex justify-content-between">
            <div>
              <h4 class="heading">
                Ticket ID:
                {{ supportTicketDetail ? supportTicketDetail.ticketNo : "" }}
              </h4>
              <p class="mb-0">
                {{ supportTicketDetail ? supportTicketDetail.description : "" }}
              </p>
            </div>
            <div>
              <div class="btn">
                <b-button
                  variant="success"
                  v-if="supportTicketDetail.status === 'OPEN'"
                  >{{
                    supportTicketDetail ? supportTicketDetail.status : ""
                  }}</b-button
                >
                <b-button
                  variant="danger"
                  v-else-if="supportTicketDetail.status === 'CLOSED'"
                  >{{
                    supportTicketDetail ? supportTicketDetail.status : ""
                  }}</b-button
                >
              </div>
              <p class="mb-0">{{ dateFormat }}</p>
            </div>
          </div>
          <span>
            <h6 class="mt-3 mb-3">Support ticket activity history</h6>
            <b-table
              responsive
              head-variant="light"
              outlined
              :fields="fields"
              :items="supportTicketDetail.worknoteHistory"
            >
              <b-tr v-for="item in items" :key="item._id"></b-tr>
              <template #cell(attachments)="data">
                <a v-if="data.value.url" @click="viewAttachment(data.item)">
                  <b-icon
                    class="ml-2"
                    icon="paperclip"
                    scale="1.5"
                    rotate="45"
                  ></b-icon>
                </a>
              </template>
            </b-table>
          </span>

          <b-input-group
            class="mt-3"
            v-show="supportTicketDetail.status === 'OPEN'"
          >
            <b-form-input
              placeholder="Enter Comment"
              v-model="postComment"
            ></b-form-input>
            <b-input-group-append>
              <div class="form-group font-weight-bolder upload_file-Custome">
                <button class="file-upload-sec paper-plane" variant="primary">
                  <b-icon icon="paperclip" rotate="45"></b-icon>
                </button>
                <b-form-file
                  placeholder="Upload file"
                  drop-placeholder="Drop file here..."
                  :state="Boolean(attachments)"
                  @change="readLogo"
                >
                </b-form-file>
              </div>

              <b-button
                variant="primary"
                class="paper-plane"
                @click="onClickComment()"
              >
                <b-icon icon="cursor-fill"></b-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <div v-if="attachments != ''">
            <Span>
              <strong> File Uploaded: </strong>
              <a class="onHover" @click="viewAttachmentFile(attachments)">
                {{ attachments }}
              </a></Span
            >
            <b-button
              class="ml-3"
              variant="primary"
              @click="onClickRemoveFile()"
            >
              Remove File
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import Vue from "vue";

export default {
  name: "support-ticket",
  data() {
    return {
      attachments: "",
      items: "",
      postComment: "",
      ticket_id: "",
      tenantId: "",
      fields: [
        {
          key: "updatedBy.name",
          label: "Name",
        },
        {
          key: "description",
          label: "Description",
        },
        {
          key: "updatedAt",
          label: "Date",
          formatter: "dateFormater",
        },
        {
          key: "attachments",
          label: "Attachment",
          formatter: "attachmentLink",
          tdClass: () => {
            return "onHover";
          },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      supportTicketDetail: (state) => state.profile.supportTicketDetail,
      userInfo: (state) => state.auth.userInfo,
    }),
    dateFormat() {
      return moment(this.supportTicketDetail.createdAt).format("DD/MM/YYYY");
    },
  },
  methods: {
    viewAttachment(data) {
      window.open(data.attachments.url, "_blank");
    },
    viewAttachmentFile(data) {
      window.open(data, "_blank");
    },
    dateFormater() {
      return moment(this.supportTicketDetail.worknoteHistory.createdAt).format(
        "DD/MM/YYYY"
      );
    },
    async onClickComment() {
      let payloadData = {
        payload: {
          type: "WORKNOTE",
          comment: {
            updatedBy: {
              name: `${this.userInfo.name.first} ${this.userInfo.name.last}`,
              email: this.userInfo.authentication.email,
              userType: this.userInfo.category.toUpperCase(),
              phone: this.userInfo.authentication.phone,
            },
            description: this.postComment,
            attachments: { url: this.attachments },
          },
        },
        ticketId: { ticket_Id: this.ticket_id },
      };
      await this.$store.dispatch("profile/supportTicketComment", payloadData);
      this.postComment = "";
      this.attachments = "";
      await this.$store.dispatch("profile/fetchSupportTicketDetail", {
        ticketId: this.ticket_id,
        tenantId: this.userInfo.tenant,
      });
      this.$swal({
        text: "Comment added Successfully!.",
        type: "success",
        title: "Success",
        timer: 1100,
      });
    },
    onClickRemoveFile() {
      this.attachments = "";
    },
    readLogo(e) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        this.uploadImage(reader.result);
      };
      reader.onerror = (err) => {
        this.$toasted.error("Image not uploaded..please try again!");
        console.log(err);
      };
    },

    async uploadImage(image) {
      let dataobj = {
        image: image,
        key: (
          Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
        ).toLowerCase(),
        isPublic: true,
        replaceExisting: true,
        basePath: "tenant",
      };
      this.showCustomToast();
      await this.$store
        .dispatch("healthCare/uploadImageToStorage", dataobj)
        .then((res) => {
          if (res) {
            this.clearCustomToast();
            this.attachments = res.data.data.fileUrl;
          }
        })
        .catch((error) => {
          this.clearCustomToast();
          this.$toasted.error("Image not uploaded..please try again!");
          console.log(error);
        });
    },

    showCustomToast() {
      Vue.toasted.show("Uploading...", {
        type: "info",
        theme: "toasted-primary",
        singleton: true,
        duration: 1000000,
      });
    },
    clearCustomToast() {
      Vue.toasted.clear();
    },
  },
  async mounted() {
    this.loading = true;
    this.ticket_id = this.$route.params.id;
    await this.$store.dispatch("profile/fetchSupportTicketDetail", {
      ticketId: this.ticket_id,
      tenantId: this.userInfo.tenant,
    });
    this.loading = false;
  },
};
</script>

<style scope>
.hidden_header {
  display: none;
}

.type_msg {
  background-color: #f0f0f0 !important;
  border: 1px solid #d8d8d8 !important;
  height: 44px !important;
  overflow-y: auto;
}

.type_msg:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.upload_file-Custome {
  display: flex;
}

.upload_file-Custome p {
  display: inline-block;
  line-height: 3;
  cursor: pointer;
}

.custom-file-label {
  cursor: pointer;
}

.file-upload-sec {
  height: 38px;
  right: 4px;
  width: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #f8f8f8;
  background-color: #ec4e0f !important;
  overflow: hidden;
  transition: all 1s;
  color: #808080;
  font-size: 22px;
  cursor: pointer;
}

.custom-file {
  position: absolute;
  width: 50px;
  top: 13px;
  height: 47px;
  opacity: 0;
  cursor: pointer;
}

.paper-plane {
  right: 8px;
  left: 0px;
  height: 38px;
  width: 45px;
  background-color: #ec4e0f !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}

.onHover {
  color: blue;
  cursor: pointer;
}
</style>
